import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import "../styles/landingpage_doityourselfie.scss";
import "../styles/landing_analiseperfil.css";
import { MercadoPagoButton } from "../component/mpbutton.js";
import emailjs from "@emailjs/browser";
import "../styles/landing_conexoes.css"


export const Landing_conexoes = () => {
  const [paymodal, SetPaymodal] = useState(false);
  const sizerem = useRef();
  const sizeremcurso = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useRef();
  const [dataform, SetDataform] = useState({
    user_name: "",
    user_email: "",
    user_celular: "",
    user_pais: "",
    user_redesocial: "",
  });
  const [alertmessage, SetAlertMessage] = useState(false);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 20)
      : (sizerem.current = 20 + (5 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 16)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 12)
      : (sizeremcurso.current = 12 + (4 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
  };

  calcsizeimg();
  calcsizeimgcurso();

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "contactme@kikapeppermint",
        "template_1miu8vu",
        form.current,
        "YYH8R-60Dx8z_9NQI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    SetPaymodal(!paymodal);
    console.log("teste");
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#db2548" }}>
        <div
          className="text-white d-flex flex-wrap justify-content-center pt-5 pb-5"
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid"
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    className="p-2 mt-2"
                    style={{ fontFamily: "Shorelines", color: "#db2548" }}
                    id="title"
                  >
                    [kika PeppeminT]
                  </div>
                </Link>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <div>
            <img
              src="conexoes.PNG"
              className="img-fluid"
              style={{ width: sizeremcurso.current }}
            />
          </div>

          <h6 className="container mb-4 mt-4 text-white" id="sizeabletext">
            Sabia que uma comunicação visual atraente e humanizada pode ser a
            chave para construir uma marca forte, envolvente e altamente
            engajada nas redes sociais?
          </h6>

          <div>
            <img
              src="squarekika.jpg"
              alt=""
              className="img-fluid rounded-circle"
              id="peppermint"
              style={{ width: sizerem.current }}
            />
          </div>
          <h4 className="container mb-4 mt-4" id="sizeabletext">
            Na mentoria Conexões, você aprende a criar material visual para um
            conteúdo humanizado e mais atrativo para sua marca
          </h4>

          <div className="container text-center">
            <a
              href="https://pay.hotmart.com/P96653165U" // Substitua essa URL pelo link do seu produto na Hotmart
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-lg mt-4 text-white w-auto" // Use w-auto para largura automática
              style={{
                backgroundColor: "#DB9E25",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
                textDecoration: "none", // Remove o sublinhado do link
                padding: "10px 20px", // Ajusta o padding para controlar a altura
                display: "inline-block", // Garante que o botão se ajuste ao conteúdo
              }}
            >
              COMPRE AGORA A MENTORIA "CONEXÕES"
            </a>
          </div>
        </div>
      </div>
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {paymodal === false ? (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex flex-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className="img-fluid"
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <h6
                  className="modal-title mb-4"
                  id="exampleModalLabel"
                  style={{ textAlign: "justify" }}
                >
                  Antes de prosseguir, precisamos de algumas informações de
                  contato. Após o preenchimento, clique em "Seguir para
                  Pagamento".
                </h6>
                {alertmessage && (
                  <h6
                    className="text-center"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    Você precisa preencher todos os dados antes de seguir para
                    pagamento!
                  </h6>
                )}
                <div>
                  <form
                    className="container-fluid"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    {["Nome", "Celular", "e-mail", "País", "@ Rede Social"].map(
                      (label, index) => (
                        <div className="input-group mb-2" key={index}>
                          <span
                            className="input-group-text text-white"
                            style={{
                              width: "130px",
                              backgroundColor: "#db2548",
                            }}
                          >
                            {label}:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={label}
                            aria-label={label}
                            name={`user_${label
                              .toLowerCase()
                              .replace(" ", "_")}`}
                            onChange={(e) => {
                              SetDataform({
                                ...dataform,
                                [`user_${label
                                  .toLowerCase()
                                  .replace(" ", "_")}`]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn text-white p-2"
                        data-bs-dismiss="modal"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          SetAlertMessage(false);
                        }}
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        className="btn text-white p-2"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          console.log(dataform);
                          if (
                            dataform.user_redesocial !== "" &&
                            dataform.user_pais !== "" &&
                            dataform.user_email !== "" &&
                            dataform.user_celular !== "" &&
                            dataform.user_name !== ""
                          ) {
                            console.log("pode enviar");
                            sendEmail();
                          } else {
                            console.log("NÃO pode enviar");
                            SetAlertMessage(true);
                          }
                        }}
                      >
                        Seguir para Pagamento
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    SetPaymodal(!paymodal);
                    console.log("fechar");
                  }}
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex flex-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className="img-fluid"
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="p-2 mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <h6
                  className="modal-title mb-4 text-center"
                  id="exampleModalLabel"
                >
                  Faça o pagamento abaixo pelo Mercado Pago ou por Cartão de
                  Crédito clicando no botão "Pagar".
                </h6>
                <div
                  className="text-center mt-1 mb-1 p-3 rounded text-white"
                  style={{ backgroundColor: "#db2548" }}
                >
                  <h3
                    className="rounded"
                    style={{
                      backgroundColor: "#fff",
                      color: "#db2548",
                      fontWeight: "bold",
                    }}
                  >
                    Consultoria Do It YourSelfie
                  </h3>

                  <h3
                    className="rounded"
                    style={{
                      backgroundColor: "#fff",
                      fontWeight: "bold",
                      color: "#db2548",
                    }}
                  >
                    R$ 177,00
                  </h3>
                  <span>Esse valor pode ser parcelado com juros do cartão</span>
                </div>

                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn text-white"
                    style={{ backgroundColor: "#db2548" }}
                    onClick={() => {
                      SetPaymodal(!paymodal);
                      console.log("teste");
                    }}
                    data-bs-dismiss="modal"
                  >
                    Sair
                  </button>
                  <div>
                    <MercadoPagoButton
                      src={
                        "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js"
                      }
                      preferenceId={
                        "1338322951-44b22bf4-a935-465e-b8bf-b826af95e4a9"
                      }
                      source={"button"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div className="d-flex row flex-wrap justify-content-center">
        <div className="d-flex justify-content-center mb-4 mt-4">
          <div className="p-1 text-center">
            <h4
              className="container p-2"
              id="sizeabletext3"
              style={{ color: "#db2548" }}
            >
              Você faz parte da sua marca e o objetivo da mentoria é que você se
              envolva nesse processo, seja independente na criação de conteúdo
              no cotidiano, e assim gerar mais conexão ao transmitir a sua
              identidade, seus valores e sua mensagem enquanto marca, de forma
              clara e autêntica para sua audiência.
            </h4>

            <h4
              className="container p-2"
              id="sizeabletext3"
              style={{ color: "#db2548" }}
            >
              Além disso, durante a mentoria, você terá exercícios e receberá
              orientações personalizadas e adaptadas às suas necessidades e
              objetivos, para que possa melhorar ainda mais a sua comunicação
              visual e obter resultados ainda melhores.
            </h4>
          </div>
        </div>
      </div>

      <h1 className="p-2 mt-2 display-5 text-center">
        <b className="" style={{ color: "#db2548" }}>
          &nbsp; Pra quem é a MENTORIA
        </b>
      </h1>

      <div className="container justify-content-center gap-3 p-2">
        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text ">
            <ul className="list-group list-group-flush mx-auto ">
              <li
                className="d-flex list-group-item "
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você deseja mostrar sua essência e autenticidade no
                  material visual da sua marca.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto ">
              <li
                className="d-flex list-group-item"
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você é uma empreendedora que deseja ter relacionamento mais
                  próximo e significativo com a audiência nas redes sociais
                  profissionais.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto ">
              <li
                className="d-flex list-group-item "
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="tbi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  className="float-start"
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você precisa destravar seus stories e contar histórias
                  realmente relevantes e ter a atenção da sua audiência.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto">
              <li
                className="d-flex list-group-item"
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2 align-self-stretch"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você precisa de orientação de forma mais próxima para criar
                  fotos e vídeos que serão usados em todas as ferramentas do seu
                  conteúdo digital.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto">
              <li
                className="d-flex list-group-item"
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2 align-self-stretch"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você é uma pessoa que se sente mais confiante para criar
                  conteúdo, se tiver a possibilidade de trocar ideias com alguém
                  mais experiente.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto">
              <li
                className="d-flex list-group-item"
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2 align-self-stretch"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se seu perfil parece uma vitrine de produtos/serviços e quase
                  nenhum conteúdo útil que gere conexão com os seguidores.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto">
              <li
                className="d-flex list-group-item"
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2 align-self-stretch"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você quer criar conteúdo autoral e autêntico para se
                  diferenciar no seu nicho de atuação.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="container col-lg-6 card border-0 justify-content-center">
          <div className="card-text">
            <ul className="list-group list-group-flush mx-auto">
              <li
                className="d-flex list-group-item"
                style={{ color: "#db2548", fontSize: "28px" }}
              >
                <i
                  className="bi bi-caret-right-fill me-2 align-self-stretch"
                  style={{ color: "#DB9E25" }}
                ></i>
                <p
                  id="sizeabletext3"
                  style={{ margin: "auto", textAlign: "justify" }}
                >
                  Se você quer virar o jogo da sua divulgação gerando mais
                  confiança e proximidade com sua audiência e, consequentemente,
                  vender mais.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="d-flex row flex-wrap justify-content-center mt-4"
        style={{ backgroundColor: "#fff" }}
      >
        <h1 className="p-2 text-center mt-2">
          <span className="" style={{ color: "#db2548" }}>
            {" "}
            DIFERENCIAL
          </span>
        </h1>

        <div className="container justify-content-center gap-3 p-2">
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Meu diferencial é que meu método CONEXÕES para humanização
                    do perfil profissional não se resume nem se aplica somente
                    às redes sociais da mulher empreendedora, mas de forma
                    abrangente no posicionamento enquanto divulgadora da marca.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Meu método é consciente sobre as demandas de tempo e
                    disponibilidade emocional.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Prioriza o bem-estar e autoconexão da mulher empreendedora,
                    para internalização da comunicação humanizada como um todo.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Conta com assessoria semanal para tirar dúvidas via
                    WhatsApp.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Contém um bônus surpresa no final que vai facilitar sua
                    criação de conteúdo humanizado.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container mt-3 mb-2">
          <div className="row justify-content-center">
            {" "}
            {/* Usando row e justify-content-center para centralizar */}
            <div className="col-lg-6 col-md-8 col-12">
              {" "}
              {/* Coluna menor */}
              <h1 className="p-2 text-center mt-4">
                <span style={{ color: "#db2548" }}>
                  COMO É A MENTORIA CONEXÕES
                </span>
              </h1>
              <div className="mb-4">
                <p className="text-center mt-4">
                  A mentoria conexões acontece em 5 encontros via vídeo chamada.
                </p>
              </div>
              <div className="mb-4 mt-4">
                <h5 style={{ color: "#db2548" }}>
                  Diagnóstico de posicionamento do perfil profissional:
                </h5>
                <p>
                  Será feita uma análise das redes pelo ponto de vista da
                  comunicação humanizada e será entregue um relatório em PDF com
                  estratégias iniciais de ação.
                </p>
              </div>
              <div className="mb-4">
                <h5 style={{ color: "#db2548" }}>Aula 1: Contando Stories:</h5>
                <p>
                  Nessa aula com duração de 1h30, você aprende a técnica para
                  criar stories que contam histórias emocionantes e relevantes.
                  Será enviado também um relatório em PDF com o conteúdo da
                  aula.
                </p>
              </div>
              <div className="mb-2">
                <h5 style={{ color: "#db2548" }}>Aula 2: Do it YourSelfie:</h5>
                <p>
                  Aula com duração de 1h30, você irá aprender a técnica para
                  fazer fotos de si mesma, que serão usadas no seu cotidiano
                  para a criação de posts, stories, capas de vídeos etc. Serão
                  abordados os conceitos de: Luz, cenário, enquadramentos,
                  composição, poses, meios de fazer cliques à distância etc.
                </p>
              </div>
              <div className="mb-4">
                <p>
                  Todo esse material será reunido num relatório que será
                  entregue em PDF. Também serão propostos exercícios para que
                  você comece a ter imagens de si mesma com intenção
                  estratégica.
                </p>
              </div>
              {/* Continuação das seções */}
              <div className="mb-2">
                <h5 style={{ color: "#db2548" }}>
                  Aula 3 "Do it Yourselfie vlog":
                </h5>
                <p>
                  Nessa aula com duração de 1h30 você irá aprender a técnica
                  para fazer vídeos de si mesma sem depender de ninguém, ou, se
                  precisar de ajuda, saber dirigir a cena para obter os
                  resultados desejados. Esses vídeos serão utilizados no
                  cotidiano da criação de conteúdo. Serão abordados conceitos
                  mais profundos como montagem de cenário, movimentos e
                  transições de câmera, narração, princípios de edição.
                </p>
              </div>
              <div className="mb-4">
                <p>
                  Esse conteúdo será entregue num relatório em PDF. Também serão
                  propostos exercícios para que você comece a ter vídeos de si
                  mesma com intenção estratégica.
                </p>
              </div>
              <div className="mb-2">
                <h5 style={{ color: "#db2548" }}>
                  Aula 4 "Aplicação no conteúdo":
                </h5>
                <p>
                  Nessa aula com duração de 1h30 você irá aprender como aplicar
                  o material visual criado nas aulas anteriores no seu conteúdo.
                  Dicas estratégicas de como criar capas para vídeos e posts,
                  legendas com SEO (palavras chave que ajudam as pessoas a
                  encontrarem seu perfil), método para montagem de posts, vídeos
                  e stories.
                </p>
              </div>
              <div className="mb-4">
                <p>
                  Será entregue um relatório em PDF. E serão propostos
                  exercícios para que você coloque em prática o que aprendeu na
                  mentoria.
                </p>
              </div>
              <div className="mb-2">
                <h4 style={{ color: "#db2548" }}>BÔNUS</h4>
                <h5 className="mt-4" style={{ color: "#db2548" }}>
                  Assessoria:
                </h5>
                <p>
                  Durante a mentoria você poderá tirar dúvidas sobre os
                  exercícios propostos, receber dicas e orientações semanalmente
                  de forma individual com hora marcada, via WhatsApp com duração
                  de 45 minutos.
                </p>
                <h5 className="mt-4" style={{ color: "#db2548" }}>
                  Calendário com 15 ideias de postagem no feed:
                </h5>
                <p>
                  Com esse bônus você vai ter aquele start na sua criação de
                  conteúdo humanizado pra começar a colher os resultados da sua
                  conexão mais próxima com seu público.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-auto mt-1 mb-2">
          <h1 className="p-2 text-center mt-4">
            <span className="" style={{ color: "#db2548" }}>
              {" "}
              Tudo isso por apenas:
            </span>
          </h1>
          <div
            className="text-center mt-1 mb-4 p-3 rounded text-white"
            style={{ backgroundColor: "#db2548" }}
          >
            <h3
              className="rounded"
              style={{
                backgroundColor: "#fff",
                color: "#db2548",
                fontWeight: "bold",
              }}
            >
              Valor total de €305
            </h3>

            <a
              href="https://pay.hotmart.com/P96653165U" // Substitua essa URL pelo link do seu produto na Hotmart
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-lg mt-4 text-white w-auto" // Use w-auto para largura automática
              style={{
                backgroundColor: "#DB9E25",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
                textDecoration: "none", // Remove o sublinhado do link
                padding: "10px 20px", // Ajusta o padding para controlar a altura
                display: "inline-block", // Garante que o botão se ajuste ao conteúdo
              }}
            >
              CLIQUE AQUI PARA COMPRAR AGORA
            </a>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <h4 className="container mb-4 mt-4" id="sizeabletext">
            QUEM SOU EU
          </h4>

          <div className="">
            <img
              src="quemsoueulp2.jpg"
              alt=""
              className="img-fluid rounded-circle"
              id="peppermint"
              style={{ width: sizerem.current }}
            />
          </div>

          <h6 className="container mb-2 mt-4 text-white" id="sizeabletext">
            Eu sou Kika Peppermint, fotógrafa, videomaker, criadora de conteúdo,
            especialista em humanização digital e contadora de histórias. Ensino
            mulheres empreendedoras a construir relacionamento e conexão nas
            redes sociais, de forma mais consciente, atrativa e engajante.
          </h6>
        </div>
      </div>
      <div
        className="d-flex row flex-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div
  className="container-fluid col-lg-10 border rounded-4"
  style={{ backgroundColor: "#fff" }}
>
  <h1 className="m-5 display-5 text-center">
    <b className="" style={{ color: "#db2548" }}>
      O que minhas últimas alunas acharam sobre a mentoria conexões
    </b>
  </h1>

  <div className="d-flex justify-content-center">
    <div
      className="d-flex flex-wrap justify-content-center mb-4"
      id="containerteste"
    >
      {[ 
        <img src="depconexoes1.jpg" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes2.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes3.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes4.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes5.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes6.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes7.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes8.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes9.PNG" alt="" className="img-fluid mb-2" />,
        <img src="depconexoes10.PNG" alt="" className="img-fluid mb-2" />
      ].map((num, index) => (
        <div
          key={index}
          className={`item ${activeIndex === index ? "active rounded" : "rounded"}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave()}
        >
          {num}
        </div>
      ))}
    </div>
  </div>
</div>


        <div className="container text-center mb-4">
          <a
            href="https://pay.hotmart.com/P96653165U" // Substitua essa URL pelo link do seu produto na Hotmart
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-lg mt-4 text-white w-auto" // Use w-auto para largura automática
            style={{
              backgroundColor: "#DB9E25",
              fontWeight: "bold",
              textShadow: "2px 2px 4px #000",
              textDecoration: "none", // Remove o sublinhado do link
              padding: "10px 20px", // Ajusta o padding para controlar a altura
              display: "inline-block", // Garante que o botão se ajuste ao conteúdo
            }}
          >
            Tem um cupom? - CLIQUE AQUI PARA COMPRA AGORA
          </a>
        </div>
      </div>

      <div className="container d-flex flex-wrap justify-content-center">
        <div className="p-4 d-flex">
          <img
            className="img-fluid"
            src="unnamed222.jpg"
            alt=""
            style={{
              width: "17rem",
              borderRadius: "50%",
              margin: "auto",
            }}
            id="peppermint"
          />
        </div>

        <div className="p-0" style={{ margin: "auto" }}>
          <h5 className="text-center">
            Caso você tenha alguma dúvida sobre a Mentoria Conexões, mande um
            e-mail para
          </h5>

          <h5 className="text-center p-0 mb-4">
            <font className="text-center p-0" style={{ fontWeight: "bold" }}>
              <a
                href="mailto:contactme@kikapeppermint.com"
                style={{ textDecoration: "none", color: "#db2548" }}
              >
                contactme@kikapeppermint.com
              </a>
            </font>
          </h5>
        </div>
      </div>

      <footer
        className="container footer stick-bottom d-flex flex-wrap justify-content-between align-items-center rounded mb-1"
        id="#footer"
        style={{ bottom: "0", backgroundColor: "#db2548" }}
      >
        <div className="col-md-5 d-flex text-white">
          <span>© 2023 Kika Peppermint</span>
        </div>

        <div className="col-md-5 d-flex justify-content-end">
          <a
            href="https://www.instagram.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-instagram me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.facebook.com/StudioPepperMint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-facebook me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://br.pinterest.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-pinterest me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.tiktok.com/@kikapeppermint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-tiktok me-1"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};
