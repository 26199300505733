import React, { useEffect } from "react";
import PropTypes from 'prop-types';
// import { Context } from "../store/appContext";
import { Link } from "react-router-dom";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

export const Checkout = (props) => {

  // const amount = "97";
  const amount = props.value;
  const currency = "BRL";
  const style = { layout: "vertical", shape: "pill", color: "black" };

  const ButtonWrapper = ({ currency, showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          currency: currency,
        },
      });
    }, [currency, showSpinner]);
    return (
      <>

        {showSpinner && isPending && <div className="spinner" />}
        <PayPalButtons
          style={style}
          disabled={false}
          forceReRender={[amount, currency, style]}
          fundingSource={undefined}
          createOrder={(data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              })
              .then((orderId) => {
                // Your code here after create the order
                return orderId;
              });
          }}
          onApprove={function (data, actions) {
            return actions.order.capture().then(function () {
              // Your code here after capture the order
            });
          }}
        />
      </>
    );
  };


return (
  <div style={{ maxWidth: "750px" }}>
          <PayPalScriptProvider
            options={{
              "client-id":
                "AZSiy080D1bK5URuJVD2Vz-aJiVnkCfOcNZxeZ07rSADoGjyXiAKa14vVtCBa4Dcvkm1P1mjbnr53xY9",
              components: "buttons",
            }}
          >
            <ButtonWrapper currency={currency} showSpinner={false} />
          </PayPalScriptProvider>
        </div>
)


}

Checkout.propTypes = {
value: PropTypes.string,
}