import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import "../styles/landingpage_analiseperfil.scss";
import "../styles/landing_analiseperfil.css";
import { MercadoPagoButton } from "../component/mpbutton.js";
import emailjs from "@emailjs/browser";

export const Gifs = () => {
  const [paymodal, SetPaymodal] = useState(false);
  const sizerem = useRef();
  const sizeremcurso = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useRef();
  const [dataform, SetDataform] = useState({
    user_name: "",
    user_email: "",
    user_celular: "",
    // user_pais: "",
    user_redesocial: "",
  });
  const [alertmessage, SetAlertMessage] = useState(false);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 20)
      : (sizerem.current = 20 + (5 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 16)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 12)
      : (sizeremcurso.current = 12 + (4 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
    // window.location.reload();
  };

  calcsizeimg();
  calcsizeimgcurso();

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm(
        "contactme@kikapeppermint",
        "template_1miu8vu",
        form.current,
        "YYH8R-60Dx8z_9NQI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    SetPaymodal(!paymodal);
    console.log("teste");
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#db2548" }}>
        <div
          className="text-white d-flex flex-lg-wrap justify-content-center pt-5 pb-5"
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className=""
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    className="p-2 mt-2"
                    style={{ fontFamily: "Shorelines", color: "#db2548" }}
                    id="title"
                  >
                    [kika PeppeminT]
                  </div>
                </Link>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <h1>GIFs Personalizados e Humanizados</h1>

          <h5 className="container mb-4 mt-4 text-white" id=" ">
            Para fazer os gifs são necessários 5 videos de 3 a 6 segundos cada,
            com expressões e movimentação que a cliente quiser e desde que não
            infrinja os termos de uso do GIPHY (são proibidos gestos obscenos,
            nudez, violência, etc).
          </h5>

          <div className="container text-center">
            <button
              type="button "
              className="btn btn-lg mt-2 mb-2 text-white"
              style={{
                backgroundColor: "#DB9E25",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
              }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              5 GIFs personalizados por R$ 97,00
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {paymodal === false ? (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex  flex-lg-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className=""
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <h6
                  className="modal-title mb-4"
                  id="exampleModalLabel"
                  style={{ textAlign: "justify" }}
                >
                  Antes de prosseguir precisamos de algumas informações de
                  contato. Após o preenchimento clique em "Seguir para
                  Pagamento".
                </h6>
                {alertmessage === true ? (
                  <h6
                    className="text-center"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    Você precisa preencher todos os dados antes de seguir para
                    pagamento!
                  </h6>
                ) : (
                  ""
                )}
                <div className="">
                  <form
                    className="container-fluid"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        Nome:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="user_name"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_name: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        WhatsApp:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Contato do WhatsApp"
                        aria-label="Celular"
                        aria-describedby="basic-addon1"
                        name="user_celular"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_celular: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        E-mail:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="e-mail"
                        aria-label="Useremail"
                        aria-describedby="basic-addon1"
                        name="user_email"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_email: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    {/* <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        País:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="País"
                        aria-label="Userpais"
                        aria-describedby="basic-addon1"
                        name="user_pais"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_pais: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div> */}
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        @ Rede Social
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="@ das suas principais Redes Sociais"
                        aria-label="Userredesocial"
                        aria-describedby="basic-addon1"
                        name="user_redesocial"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_redesocial: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn text-white p-2"
                        data-bs-dismiss="modal"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          SetAlertMessage(false);
                        }}
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        className="btn text-white p-2"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          console.log(dataform);
                          if (
                            dataform.user_redesocial !== "" &&
                            // dataform.user_pais !== "" &&
                            dataform.user_email !== "" &&
                            dataform.user_celular !== "" &&
                            dataform.user_name !== ""
                          ) {
                            console.log("pode enviar");
                            sendEmail();
                          } else {
                            console.log("NÃO pode enviar");
                            SetAlertMessage(true);
                          }
                        }}
                      >
                        Seguir para Pagamento
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    SetPaymodal(!paymodal);
                    console.log("fechar");
                  }}
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex flex-lg-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className=""
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="p-2 mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <h6
                  className="modal-title mb-4 text-center"
                  id="exampleModalLabel"
                >
                  Faça o pagamento abaixo pelo Mercado Pago ou por Cartão de
                  Crédito clicando no botão "Pagar".
                </h6>
                <div className="">
                  <div
                    className="text-center mt-1 mb-1 p-3 rounded text-white"
                    style={{ backgroundColor: "#db2548" }}
                  >
                    <h3
                      className="rounded"
                      style={{
                        backgroundColor: "#fff",
                        color: "#db2548",
                        fontWeight: "bold",
                      }}
                    >
                      5 GIFs Personalizados 
                    </h3>

                    <h3
                      className="rounded"
                      style={{
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        color: "#db2548",
                      }}
                    >
                      R$ 97,00
                    </h3>
                    <span>
                      Esse valor pode ser parcelado com juros do cartão
                    </span>
                  </div>

                  <div className="modal-footer justify-content-center">
                    <button
                      type="button"
                      className="btn text-white"
                      style={{ backgroundColor: "#db2548" }}
                      onClick={() => {
                        SetPaymodal(!paymodal);
                        console.log("teste");
                      }}
                      data-bs-dismiss="modal"
                    >
                      Sair
                    </button>
                    <div>
                      <MercadoPagoButton
                        src={
                          "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js"
                        }
                        preferenceId={
                          "1338322951-225485ee-dd3a-4a16-bfa2-54e81e6e1b8e"
                        }
                        source={"button"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container card border-0 mb-2 mt-4">
        <div className="card-text ">
          <ul className="list-group list-group-flush">
            <li
              className="d-flex list-group-item rounded text-center"
              style={{
                color: "#db2548",
                fontSize: "28px",
                backgroundColor: "#db2548",
              }}
            >
              <p
                className="text-white"
                id="sizeabletext3"
                style={{ margin: "auto" }}
              >
                Siga as recomendações abaixo para garantir melhor qualidade e
                utilidade prática dos seus gifs.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="container d-md-flex flex-wrap flex-md-nowrap align-self-start">
        <div className="col p-2 me-md-4">
          <h1 className="mt-2 display-5 text-center">
            <h5 className="" style={{ color: "#db2548" }}>
              {" "}
              &nbsp; Recomendo que os vídeos sejam gravados da seguinte forma:
            </h5>
          </h1>

          <div className="container justify-content-center gap-3 p-2 mt-3">
            <div className="col">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Com a câmera traseira do celular (ou uma câmera tradicional);
                </h6>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item ">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Com boa iluminação frontal ou diagonal (sem uma boa claridade
                  no rosto a qualidade do gif ficará prejudicada);
                </h6>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item ">
                <i
                  className="tbi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Fundo de cor lisa (sem objetos, texturas, desenhos (etc));
                </h6>
              </li>
            </div>
            <div className="col ">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Grave de frente pra câmera com o fundo reto atrás (sem
                  perspectiva e ângulo);
                </h6>
              </li>
            </div>
            <div className="col ">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Use um enquadramento de preferência do quadril pra cima com
                  espaço acima da cabeça e dos lados para movimentação dos
                  braços sem cortes (evite enquadramentos errados para que não
                  seja necessário recortar o video);
                </h6>
              </li>
            </div>
          </div>
          <li className="list-group-item position-relative">
            <div
              className="position-absolute top-100 start-0 translate-middle"
              style={{
                width: "18.5%",
                paddingBottom: "7.5%",
              }}
            >
              <img
                src="IMG_3072.gif"
                alt="Animated GIF"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </li>
        </div>

        <div className="col p-2 ">
          <h1 className="mt-2 display-5 text-center">
            <h5 className="" style={{ color: "#db2548" }}>
              {" "}
              &nbsp; Sugestões de ações:
            </h5>
          </h1>

          <div className="container justify-content-center gap-3 p-2">
            <div className="col">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>Apontar para cima</h6>
              </li>
              <li className="list-group-item position-relative ms-2">
                <div
                  className="position-absolute top-0 end-0 translate-middle"
                  style={{
                    width: "23.5%",
                    paddingBottom: "2.5%",
                  }}
                >
                  <img
                    src="IMG_0424.gif"
                    alt="Animated GIF"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item ">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>Apontar para o lado</h6>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item ">
                <i
                  className="tbi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>Aplaudir</h6>
              </li>
            </div>
            <div className="col ">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Fazer joinha com as maos
                </h6>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>Dar tchauzinho</h6>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>Mandar beijo</h6>
              </li>
            </div>
            <div className="col ">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Negar com a cabeça e a expressão zangada
                </h6>
              </li>
            </div>
            <div className="col ">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Fazer sim com a cabeça concordando
                </h6>
              </li>
            </div>
            <div className="col ">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Qualquer ação referente ao nicho de trabalho
                </h6>
              </li>
            </div>
            <div className="col">
              <li className="d-flex list-group-item">
                <i
                  className="bi bi-caret-right-fill me-2"
                  style={{ color: "#DB9E25" }}
                ></i>
                <h6 style={{ textAlign: "justify" }}>
                  Qualquer ação em humorada que gere conexão com sua audiência
                </h6>
              </li>
            </div>
          </div>
        </div>
      </div>

      <footer
        className="container footer stick-bottom d-flex flex-wrap justify-content-between align-items-center rounded mb-1"
        id="#footer"
        style={{ bottom: "0", backgroundColor: "#db2548" }}
      >
        <div className="col-md-5 d-flex text-white ">
          <span className="">© 2023 Kika Peppermint</span>
        </div>

        <div className="col-md-5 d-flex justify-content-end">
          <a
            href="https://www.instagram.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-instagram me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.facebook.com/StudioPepperMint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-facebook me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://br.pinterest.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-pinterest me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.tiktok.com/@kikapeppermint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-tiktok me-1"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};
