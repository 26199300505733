import React from "react";
import "./stories_module.css";

export const StoriesModule = () => {
  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#fff" }}>
        <div
          className="text-white d-flex flex-wrap justify-content-center pt-5 pb-5"
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.15)", // Sombra mais intensa
            transform: "scale(1.02)", // Leve efeito de zoom para dar profundidade
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className="img-fluid"
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />

                <div
                  className="p-2 mt-2"
                  style={{ fontFamily: "Shorelines", color: "#db2548" }}
                  id="title"
                >
                  [kika PeppeminT]
                </div>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div
        className="d-flex row flex-wrap justify-content-center"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container mt-3 mb-2">
          <div className="row justify-content-center">
            {" "}
            {/* Usando row e justify-content-center para centralizar */}
            <div className="col-lg-6 col-md-8 col-12">
              {" "}
              {/* Coluna menor */}
              <h1 className="p-2 text-center mt-4">
                <span style={{ color: "#db2548" }}>Mini roteiro de stories</span>
              </h1>
              <h2 className="p-2 text-center mt-4">
                <span style={{ color: "#db2548" }}>Story 1</span>
              </h2>
              <div className="mb-4"></div>
              <div className="mb-4 mt-4">
                <h5 style={{ color: "dark" }}>Opção 1:</h5>
                <p>
                  Cena de 8 a 10 segundos entrando/Chegando ao local do nosso
                  evento e colocando a mão na maçaneta pra entrar (mas não
                  mostre o que tem dentro).
                </p>
                <p>
                  Use uma música de acordo com o tema e escreva algo que indique
                  que você está indo a algum lugar especial.
                </p>
                <p>
                  Use um sticker de interação (enquete ou react) para aumentar o
                  engajamento.
                </p>
              </div>
              <div className="mb-4">
                <h5 style={{ color: "dark" }}>Opção 2:</h5>
                <p>
                  Cena de 8 a 15 segundos mostrando a si mesma e falando com a
                  câmera antes de chegar ao local do evento.
                </p>
                <p>
                  Fale sobre como foi sua manhã e que está chegando num lugar
                  (não releve onde).
                </p>
                <p>Use um sticker de enquete dando 2 opções.</p>
              </div>
              <h2 className="p-2 text-center mt-4">
                <span style={{ color: "#db2548" }}>Story 2</span>
              </h2>
              <div className="mb-4"></div>
              <div className="mb-4 mt-4">
                <h5 style={{ color: "dark" }}>Opção 1:</h5>
                <p>
                  Cena de 10 a 15 segundos. Mostre a si mesma no local sem falar
                  nada e então pare e mostre um pouco o que tem à sua volta.
                  Sempre sorrindo e animada.
                </p>
                <p>
                  Escreva brevemente onde está, o que está fazendo nesse lugar e
                  então a frase “e olha que coisa mais
                  linda/incrivel/saborosa/etc…” e termine com reticências.
                </p>
                <p>
                  Use uma musica de acordo com o tema e use também o sticker de
                  localização Aveiro
                </p>
              </div>
              <div className="mb-4">
                <h5 style={{ color: "dark" }}>Opção 2:</h5>
                <p>
                  Cena de 10 a 15 segundos mostrando o evento (com mão rme pra
                  não tremer).
                </p>
                <p>
                  Escreva algo breve sobre o evento e uma frase que gere
                  curiosidade sobre o próximo story com reticências no final.
                </p>
                <p>Use também o sticker de localização Aveiro.</p>
              </div>
              <h2 className="p-2 text-center mt-4">
                <span style={{ color: "#db2548" }}>Story 3</span>
              </h2>
              <div className="mb-4"></div>
              <div className="mb-4 mt-4">
                <h5 style={{ color: "dark" }}>Opção 1:</h5>
                <p>
                  Cena de 10 a 12 segundos mostrando a si mesma sem falar nada,
                  com algo especial especíco que você encontrou no evento/ seu
                  próprio produto. E escreva brevemente com elogios sobre.
                </p>
                <p>
                
                  Use o trecho continuação da musica usada no story anterior.
                </p>
              </div>
              <div className="mb-4">
                <h5 style={{ color: "dark" }}>Opção 2:</h5>
                <p>
                  Cena de 10 a 12 segundos mostrando a si mesma junto com algo
                  que encontrou/ seu produto no evento. E fale sobre o item com
                  elogios e que vai falar melhor dele daqui a pouco (isso é uma
                  deixa para fazer um story de uma oferta depois)
                </p>
                <p className="mb-5">
                  Resuma brevemente o que está dizendo por escrito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer
        className="container footer stick-bottom d-flex flex-wrap justify-content-between align-items-center rounded mb-1"
        id="#footer"
        style={{ bottom: "0", backgroundColor: "#fff" }}
      >
        <div className="col-md-5 d-flex">
          <span style={{ color: "#db2548" }}>© 2023 Kika Peppermint</span>
        </div>

        <div className="col-md-5 d-flex justify-content-end">
          <a
            href="https://www.instagram.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-instagram me-3"
              style={{ color: "#db2548", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.facebook.com/StudioPepperMint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-facebook me-3"
              style={{ color: "#db2548", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://br.pinterest.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-pinterest me-3"
              style={{ color: "#db2548", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.tiktok.com/@kikapeppermint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-tiktok me-1"
              style={{ color: "#db2548", fontSize: "20px" }}
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};
